.cell {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  cursor: pointer;
  display: inline-block;
  height: 1.8rem;
  margin: 0.1rem;
  width: 1.8rem; }
  .cell .cell__emoji {
    align-items: center;
    display: flex;
    font-size: 1rem;
    height: 100%;
    justify-content: center;
    width: 100%; }
