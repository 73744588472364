html {
  font-size: 62.5%;
  height: 100%;
  overflow: hidden; }

body {
  background: linear-gradient(to top right, #763490, #cd7582);
  color: #f5f5f5;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 1.4rem;
  height: 100%;
  overflow: auto;
  text-align: center; }

*,
*::before,
*::after {
  box-sizing: border-box; }
