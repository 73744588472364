.btn {
  background-color: #4c225d;
  border: none;
  border-radius: 0.4rem;
  color: #f5f5f5;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: 0.05rem;
  margin: 0.5rem;
  padding: 0.6rem 1.2rem; }
  .btn:active, .btn:focus, .btn:hover {
    background-color: #f5f5f5;
    color: #4c225d; }
  .btn:active {
    transform: translateY(0.1rem); }
